import React, { useContext, useEffect } from 'react';
import { KfAnchorLink, KfHeroUtility } from '@klickinc/kf-react-components';
import Loadable from '@loadable/component';
import AppContext from "@src/context"
// Utility imports
import { scrollToHash } from "@utils/helper"
import Seo from '@components/utility/SEO.jsx';
import Image from '@components/utility/Image';
import { withArtDirection, getImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import Sparkles from '@components/content/svgs/Sparkles';
import { Link } from "gatsby"
import './styles.scss';

const VideoPlayer = Loadable(() => import("@components/content/VideoPlayer.jsx"));
const InViewport = Loadable(() => import('@components/utility/InViewport'));

const Homepage = ({ data }) => {
	const ctx = useContext(AppContext);

	const image_bg1 = withArtDirection(getImage(data.bg1), [
		{
			media: "(min-width: 641px) and (max-width: 1023px)",
			image: getImage(data.bg1T),
		},
		{
			media: "(max-width: 640px)",
			image: getImage(data.bg1M),
		},
	]);
	const image_boyBg = getImage(data.boyBg);

	const switchItems = [
		{
			icon: 'switch--s.png',
			alt: 'Strength',
			addedClass: 'strength',
			imageData: getImage(data.strength),
		},
		{
			icon: 'switch--w.png',
			alt: 'Weight',
			addedClass: 'weight',
			imageData: getImage(data.weight),
		},
		{
			icon: 'switch--i.png',
			alt: 'Independence',
			addedClass: 'independence',
			imageData: getImage(data.independence),
		},
		{
			icon: 'switch--t.png',
			alt: 'Time',
			addedClass: 'time',
			imageData: getImage(data.time),
		},
		{
			icon: 'switch--c.png',
			alt: 'Communication',
			addedClass: 'communication',
			imageData: getImage(data.communication),
		},
		{
			icon: 'switch--h.png',
			alt: 'Health status',
			addedClass: 'health-status',
			imageData: getImage(data.healthStatus),
		},
	];

	useEffect(() => {
		ctx.setPageClass('page--home');
	}, []);

	return (
		<>
			<section className='section-fold'>
				<div className='bg-1-image'>
					<Image
						imageData={image_bg1}
						alt='Reid, age 7 and Mikey, age 9, real EMFLAZA patients'
						loading='eager'
					/>
				</div>
				<div className="container container--inner relative">
					<div className='fold-content'>
						<KfHeroUtility addedClass='heading text-emflaza-white-100'>
							<span>Playing like</span>
							<span className='h1-main'>Brothers</span>
							<span>because they can</span>
						</KfHeroUtility>
						<div className='supporting-info'>
							<p>For you and your child, right now means everything. That's why now is the time to discuss EMFLAZA&mdash;<span className='italic font-bold uppercase bg-emflaza-blue-50 text-emflaza-blue-600 pr-[0.5rem]'>the first</span> FDA&#8209;approved corticosteroid for boys<br className='hidden xl:inline' /> with Duchenne muscular&nbsp;dystrophy (DMD).</p>
							<h2>Help Extend<br /> <span className='ml-32 italic text-emflaza-blue-600 desktop:ml-24'>His Now</span>.</h2>

							<div className='supporting-info--links'>
								<Link to='/about-emflaza' className='gtm-homepage-cta homepage-cta--blue'>
									<img src="/images/icons/icon-homepage-learn-how.svg" width="46" height="46" alt="" />
									<span>See the Data</span>
								</Link>

								<Link lang='es' to='/es' className='gtm-homepage-cta homepage-cta--light-blue'>
									<img src="/images/icons/icon-homepage-info.svg" width="46" height="46" alt="" />
									<span>Información<br /> en Español</span>
								</Link>

								<a href="/#making-a-switch" onClick={scrollToHash} className='gtm-homepage-cta homepage-cta--dark-blue'>
									<img src="/images/icons/icon-homepage-play.svg" width="46" height="46" alt="" />
									<span>See How One Mom<br /> Manages DMD</span>
								</a>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className='section-what'>
				<div className="container container--inner">
					<h2 className="max-w-2xl heading heading-hero-blue">
						What you should know about corticosteroids
					</h2>
					<div className='flex flex-col space-y-10 md:justify-between md:flex-row md:space-y-0 md:space-x-12 lg:space-x-20 lg2:space-x-5 desktop:space-x-20'>
						<div className='flex-1 space-y-5 md:max-w-[35%] desktop:max-w-[40%]'>
							<h3 className='text-2xl font-black text-emflaza-orange-100'>Not all corticosteroids are the&nbsp;same.</h3>
							<p className='lg:pr-[20px]'>It's important to understand what sets them apart, especially if you are considering a switch in corticosteroid treatments. Find out what to look for and what questions to ask your son's healthcare provider to see if a switch may be right for your family.</p>
							<a href="/understanding-corticosteroids#understanding-switch" className='gtm-homepage-cta hidden md:block link-btn link-btn--blue max-w-[18.75rem]'>Learn More</a>
						</div>

						<div className='relative mobile:mx-auto'>
							<div className='lg:hidden'>
								<Sparkles height='550px' />
							</div>
							<div className='hidden lg:block'>
								<Sparkles height='725px' />
							</div>
							<div className='flex flex-col'>
								{switchItems.map((item, index) => (
									<div key={index} className={`switch__item switch__item--${item.addedClass}`} tabIndex='-1'>
										<Image
											imageData={item.imageData}
											objectFit='contain'
											alt={item.alt}
											loading='eager'
										/>
									</div>
								))}
							</div>
							<a href="/understanding-corticosteroids#understanding-switch" className='block md:hidden link-btn link-btn--blue md:max-w-[18.75rem]'>Learn More</a>
						</div>
					</div>
				</div>
			</section>

			<section className='section-connecting'>
				<div className="bg-3-image">
					<div className="container pt-4 pb-2 container--inner md:pt-20 xl:pt-4 relative">
						<div className='bg-boy'>
							<InViewport delay={250} disable={ctx.mobile} addedClass='inViewport--image'>
								<div className='boy-image'>
									<Image imageData={image_boyBg} objectFit='contain' alt='Cooking like a pro' />
									<span className='absolute desktop:mb-[1rem] bottom-[2.75rem] right-[-0.5] md:right-[1.5rem] desktop:right-[3.5rem] font-gotham text-[9px] text-emflaza-blue-600'>Dunky, Age 6 <br /><span className='italic'>Actual EMFLAZA patient.</span></span>
								</div>
							</InViewport>
						</div>
						<div className='flex flex-col items-start my-14 space-y-5 xl:flex-row xl:space-y-0 xl:space-x-10 xl:my-20'>
							<h2 id="connecting-with-other-parents" className="flex-1 pb-0 heading heading-hero-blue p-0 xl:pb-8">
								Connecting with other parents
							</h2>
							<div className='discover-support space-y-5'>
								<h3 className='text-2xl font-black text-emflaza-orange-100'>Discover the support of<br /> a Peer Navigator.</h3>
								<p>Living with DMD isn't easy, but hearing from others who have been in your shoes can be helpful. Have a chat, share experiences, and gain perspective from other parents managing DMD throughout your family's journey.</p>
								<KfAnchorLink url='https://ptccares.com/support-beyond-treatment/#connect-with-peers' target='_blank' linkClass='gtm-homepage-cta link-btn link-btn--blue sm:max-w-[18.75rem] xl:max-w-full'>Get Connected</KfAnchorLink>
							</div>
						</div>

					</div>
				</div>
			</section>

			<section id="making-a-switch">
				<div className="container container--inner pt-10 md:pt-20 xl:pt-14 relative">
					<h3 className='mb-3 text-2xl font-black text-emflaza-orange-100 max-w-[48rem]'>Making a Switch: Chistopher's Treatment Journey</h3>
					<p className='mb-5'>Sima cares for her son every day with the help of her family. Hear how she works with the DMD community and PTC <span className='italic'>Cares</span>™ along the way.</p>
					<div className='video'>
						<VideoPlayer id={768695028} />
						<div className='flex flex-col items-start'>
							<KfAnchorLink url='/video-transcript/making-a-switch' target="_blank" linkClass='gtm-homepage-cta gtm-transcript-cta transcript-cta'>View Video Transcript</KfAnchorLink>
							<a lang='es' href='/es#haciendo-un-cambio' className='transcript-cta'>Vea Video en Español</a>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default Homepage;


export const pageQuery = graphql`
  query {
    bg1: file(relativePath: {eq: "pages/homepage/page-home-bg-1.jpg"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	bg1T: file(relativePath: {eq: "pages/homepage/page-home-bg-1-tablet.jpg"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	bg1M: file(relativePath: {eq: "pages/homepage/page-home-bg-1-mobile.jpg"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	boyBg: file(relativePath: {eq: "pages/homepage/page-home-boy-bg-1.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	strength: file(relativePath: {eq: "icons/switch/switch--s.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	weight: file(relativePath: {eq: "icons/switch/switch--w.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	independence: file(relativePath: {eq: "icons/switch/switch--i.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	time: file(relativePath: {eq: "icons/switch/switch--t.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	communication: file(relativePath: {eq: "icons/switch/switch--c.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	healthStatus: file(relativePath: {eq: "icons/switch/switch--h.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
}
`
export function Head() {
	return (
		<Seo
			title='EMFLAZA® (deflazacort): DMD Treatment for Kids 2 Years & Older'
			description='Help extend his now with EMFLAZA® (deflazacort). View dosing and safety information and find out how to get started. See full Prescribing Information & Important Safety Information.'>
			<script type="application/ld+json">
				{JSON.stringify({ "@context": "http://schema.org", "type": "VideoObject", "name": "Making A Switch: Christopher's Treatment Journey", "description": "Sima cares for her son every day with the help of her family. Hear how she works with the DMD community and PTC Cares™ along the way.", "thumbnailUrl": "https://i.vimeocdn.com/video/1543947810-df66fb5c4e6c62546048f9b1e8a4e1a2787bd1a086fd38a6adc7f08b4228277d-d", "uploadDate": "2022-09-30", "embedUrl": "https://www.emflaza.com/", "contentUrl": "https://player.vimeo.com/video/768695028?loop=false&autoplay=false&muted=false&gesture=media&playsinline=true&byline=false&portrait=false&title=false&speed=true&transparent=false&customControls=true", "duration": "TPT8M34S" })}
			</script>
		</Seo>
	);
};
