import React from 'react';
import PropTypes from "prop-types"
import './styles.scss';

const Sparkles = ({height, left}) => {
	return (
		<div className={`sparkles ${left}`}>
			<img src="/images/icons/switch/plus-orange.svg" alt="" className="animated-icon orange-1" />
			<img src="/images/icons/switch/plus-orange.svg" alt="" className="animated-icon orange-2" />
			<img src="/images/icons/switch/plus-orange.svg" alt="" className="animated-icon orange-3" />
			<img src="/images/icons/switch/plus-orange.svg" alt="" className="animated-icon orange-4" />
			<img src="/images/icons/switch/plus-orange.svg" alt="" className="animated-icon orange-5" />
			<img src="/images/icons/switch/plus-blue.svg" className="animated-icon blue-1" alt="" />
			<img src="/images/icons/switch/plus-blue.svg" className="animated-icon blue-2" alt="" />
			<img src="/images/icons/switch/plus-blue.svg" className="animated-icon blue-3" alt="" />
			<img src="/images/icons/switch/circle-navy.svg" className="animated-icon navy-1" alt="" />
			<img src="/images/icons/switch/circle-blue.svg" className="animated-icon blue-4" alt="" />
			<svg width="111px" height={height} viewBox="0 0 75 135" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
				<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
					<g transform="translate(-129.000000, -79.000000)">
						<path d="M156.92,104.83 C156.385218,104.825949 155.900838,105.144975 155.693373,105.63789 C155.485907,106.130806 155.596359,106.700194 155.973077,107.079787 C156.349795,107.459381 156.918326,107.57416 157.412805,107.370448 C157.907284,107.166737 158.229985,106.684797 158.23,106.15 C158.232665,105.800837 158.095826,105.465059 157.849863,105.217219 C157.603901,104.969379 157.269173,104.82999 156.92,104.83" stroke="#001B49"></path>
						<path d="M140.68,167.83 L142.2,167.83 L142.2,169.35 C142.164142,169.684943 142.322635,170.010966 142.608191,170.189657 C142.893746,170.368348 143.256254,170.368348 143.541809,170.189657 C143.827365,170.010966 143.985858,169.684943 143.95,169.35 L143.95,167.83 L145.44,167.83 C145.887195,167.782125 146.226325,167.404751 146.226325,166.955 C146.226325,166.505249 145.887195,166.127875 145.44,166.08 L143.95,166.08 L143.95,164.57 C143.902125,164.122805 143.524751,163.783675 143.075,163.783675 C142.625249,163.783675 142.247875,164.122805 142.2,164.57 L142.2,166.09 L140.68,166.09 C140.345057,166.054142 140.019034,166.212635 139.840343,166.498191 C139.661652,166.783746 139.661652,167.146254 139.840343,167.431809 C140.019034,167.717365 140.345057,167.875858 140.68,167.84" fill="#E87325"></path>
						<path d="M201.8,152.28 C201.076507,152.28 200.49,152.866507 200.49,153.59 C200.49,154.313493 201.076507,154.9 201.8,154.9 C202.523493,154.9 203.11,154.313493 203.11,153.59 C203.11,153.242566 202.971983,152.909363 202.72631,152.66369 C202.480637,152.418017 202.147434,152.28 201.8,152.28" fill="#E87425"></path>
						<path d="M165.8,179.28 C165.076507,179.28 164.49,179.866507 164.49,180.59 C164.49,181.313493 165.076507,181.9 165.8,181.9 C166.523493,181.9 167.11,181.313493 167.11,180.59 C167.11,180.242566 166.971983,179.909363 166.72631,179.66369 C166.480637,179.418017 166.147434,179.28 165.8,179.28" fill="#45B4E2"></path>
						<path d="M148.31,122 C147.586507,122 147,122.586507 147,123.31 C147,124.033493 147.586507,124.62 148.31,124.62 C149.033493,124.62 149.62,124.033493 149.62,123.31 C149.62,122.962566 149.481983,122.629363 149.23631,122.38369 C148.990637,122.138017 148.657434,122 148.31,122" fill="#45B4E2"></path>
						<path d="M182.31,217 C181.586507,217 181,217.586507 181,218.31 C181,219.033493 181.586507,219.62 182.31,219.62 C183.033493,219.62 183.62,219.033493 183.62,218.31 C183.62,217.962566 183.481983,217.629363 183.23631,217.38369 C182.990637,217.138017 182.657434,217 182.31,217" fill="#45B4E2"></path>
						<path d="M165.31,264 C164.586507,264 164,264.586507 164,265.31 C164,266.033493 164.586507,266.62 165.31,266.62 C166.033493,266.62 166.62,266.033493 166.62,265.31 C166.62,264.962566 166.481983,264.629363 166.23631,264.38369 C165.990637,264.138017 165.657434,264 165.31,264" fill="#45B4E2"></path>
						<path d="M160.31,452 C159.586507,452 159,452.586507 159,453.31 C159,454.033493 159.586507,454.62 160.31,454.62 C161.033493,454.62 161.62,454.033493 161.62,453.31 C161.62,452.962566 161.481983,452.629363 161.23631,452.38369 C160.990637,452.138017 160.657434,452 160.31,452" fill="#45B4E2"></path>
						<path d="M159.15,246.18 C158.045431,246.18 157.15,247.075431 157.15,248.18 C157.15,249.284569 158.045431,250.18 159.15,250.18 C160.254569,250.18 161.15,249.284569 161.15,248.18 C161.15,247.075431 160.254569,246.18 159.15,246.18" stroke="#001B49"></path>
						<path d="M191.55,235.11 C192.088372,235.113832 192.57512,234.790314 192.780041,234.292452 C192.984961,233.794589 192.866983,233.222166 192.481903,232.845904 C192.096822,232.469643 191.52182,232.364955 191.028838,232.581353 C190.535855,232.79775 190.223699,233.291861 190.24,233.83 C190.239979,234.555128 190.824892,235.144507 191.55,235.15" fill="#E87425"></path>
						<path d="M146.87,318.93 C145.765431,318.93 144.87,319.825431 144.87,320.93 C144.87,322.034569 145.765431,322.93 146.87,322.93 C147.974569,322.93 148.87,322.034569 148.87,320.93 C148.87,319.825431 147.974569,318.93 146.87,318.93" stroke="#09BCEF"></path>
						<path d="M186.82,279.75 C186.096507,279.75 185.51,280.336507 185.51,281.06 C185.51,281.783493 186.096507,282.37 186.82,282.37 C187.543493,282.37 188.13,281.783493 188.13,281.06 C188.124557,280.338774 187.541226,279.755443 186.82,279.75" fill="#001B49"></path>
						<path d="M178.973675,249.056325 L180.493675,249.056325 L180.493675,250.586325 C180.54155,251.03352 180.918924,251.37265 181.368675,251.37265 C181.818426,251.37265 182.1958,251.03352 182.243675,250.586325 L182.243675,249.056325 L183.763675,249.056325 C184.21087,249.00845 184.55,248.631076 184.55,248.181325 C184.55,247.731574 184.21087,247.3542 183.763675,247.306325 L182.243675,247.306325 L182.243675,245.786325 C182.1958,245.33913 181.818426,245 181.368675,245 C180.918924,245 180.54155,245.33913 180.493675,245.786325 L180.493675,247.306325 L178.973675,247.306325 C178.638732,247.270467 178.312709,247.42896 178.134018,247.714516 C177.955327,248.000071 177.955327,248.362579 178.134018,248.648134 C178.312709,248.93369 178.638732,249.092183 178.973675,249.056325" fill="#E87325"></path>
						<path d="M143.92,384.92 C144.643493,384.92 145.23,384.333493 145.23,383.61 C145.23,382.886507 144.643493,382.3 143.92,382.3 C143.196507,382.3 142.61,382.886507 142.61,383.61 C142.61,384.333493 143.196507,384.92 143.92,384.92" fill="#E87425"></path>
						<path d="M186.49,353.75 C186.862989,353.754054 187.201511,353.532493 187.347065,353.189053 C187.49262,352.845614 187.416396,352.448276 187.154083,352.183081 C186.89177,351.917885 186.49529,351.837323 186.15028,351.979116 C185.80527,352.120909 185.580022,352.456989 185.58,352.83 C185.57997,353.334222 185.985808,353.744519 186.49,353.75" stroke="#E87425"></path>
						<path d="M191.55,433.53 C191.013411,433.525996 190.527798,433.847224 190.321549,434.342608 C190.1153,434.837993 190.229465,435.408933 190.610353,435.786914 C190.991241,436.164894 191.563039,436.274681 192.056829,436.064642 C192.550618,435.854603 192.868115,435.366543 192.86,434.83 C192.86,434.106507 192.273493,433.52 191.55,433.52" fill="#E87425"></path>
						<path d="M143.05,472.06 C142.326507,472.06 141.74,472.646507 141.74,473.37 C141.74,474.093493 142.326507,474.68 143.05,474.68 C143.773493,474.68 144.36,474.093493 144.36,473.37 C144.36,472.646507 143.773493,472.06 143.05,472.06" fill="#E87425"></path>
					</g>
				</g>
			</svg>
		</div>
	);
};

Sparkles.defaultProps = {
	height: '610px',
    left: 'left-0',
}

Sparkles.propTypes = {
    height: PropTypes.string,
    left: PropTypes.string,
}


export default Sparkles;
